import { createApp } from "vue";
import App from "./App.vue";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import { es, en } from "vuetify/locale";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import store from "./store";
//import "sweetalert2/dist/sweetalert2.min.css";
//import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure your project is capable of handling css files
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { fa } from "vuetify/iconsets/fa";
import { mdi } from "vuetify/iconsets/mdi";
import { aliases } from "vuetify/iconsets/mdi-svg";
//import * as Vue from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as labsComponents from "vuetify/labs/components";
import colors from "vuetify/lib/util/colors";
import { VBtn } from "vuetify/components";
import Swal from "sweetalert2";
//import { mdiAirplaneClock } from "@mdi/js";
import { mdiAccount } from "@mdi/js";

//import { library } from "@fortawesome/fontawesome-svg-core";
//import { faCode } from "@fortawesome/pro-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

loadFonts();

//Vue.config.productionTip = false
//Vue.use(require('vue-moment'));

const vuetify = createVuetify({
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        primary: "green",
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        background: colors.indigo.lighten5, // Not automatically applied
      },
      dark: {
        primary: colors.blue.lighten3,
        background: colors.indigo, // If not using lighten/darken, use base to return hex
      },
    },
  },
  locale: {
    locale: "es",
    fallback: "es",
    messages: { es },
  },
  icons: {
    defaultSet: "fa",
    aliases: {
      ...aliases,
      account: mdiAccount,
    },
    sets: {
      fa,
      mdi,
    },
  },
  aliases: {
    //VBtnPrimary: VBtn,
    //VBtn: VBtn,
  },
  defaults: {
    VBtn: {
      color: "green",
      variant: "elevated",
    },
    VBtnPrimary: {
      color: "green",
      variant: "elevated",
    },
  },
  components: {
    ...components,
    ...labsComponents,
    Swal,
  },

  directives,
});

createApp(App).use(router).use(store).use(vuetify).mount("#app");
