//import Vue from "vue";
import Vuex from "vuex";
import master from "./mod/master";
import access from "./mod/access";

//Vue.use(Vuex);
export const store = new Vuex.Store({
  state: {
    contador: 10,
  },
  modules: {
    master,
    access,
  },
});
export default store;
