import { createRouter, createWebHashHistory } from "vue-router";

import axios from "axios";
import store from "../store";
import routes from "./routes";
import menuitems from "@/store/mod/menuitems";
const router = createRouter({
  history: createWebHashHistory(),
  //base: process.env.BASE_URL,
  routes,
});

axios.interceptors.response.use(
  (response) => {
    //console.log(previusPath)
    store.commit("master/setLoadingBtn", false);
    /* if(previusPath !=null){
		location.href= previusPath
		previusPath=null
	} */
    return response;
  },
  (error) => {
    const { status } = error.response;

    if (status === 401) {
      // EXPIRA EL TOKEN
      let msg =
        typeof error.response.data.msg != "undefined"
          ? error.response.data.msg
          : error.response.data.detail;

      //console.log(msg, error);

      localStorage.removeItem("token");
      localStorage.removeItem("menu");
      localStorage.setItem("user", "");
      store.commit("master/setMenu", []);
      store.commit("access/setLogedIn", false);
      store.dispatch("master/alertNotification", {
        param: {
          title: "Error!",
          html:
            typeof msg == "undefined"
              ? "Su sesión ha expirado, ingrese nuevamente"
              : msg,
          timer: 25000,
          icon: "error",
          confirmButtonColor: "#d60400",
        },
      });
      //previusPath = window.location.pathname;
      router.push({ path: "/login", reload: true });
    } else if (status === 403) {
      // NO AUTORIZADO PARA LA ACCIÓN
      // store.dispatch('unAutorized')

      localStorage.removeItem("token");
      localStorage.removeItem("menu");
      localStorage.setItem("user", "");
      store.commit("access/setLogedIn", false);
      store.commit("master/setMenu", []);
      store.dispatch("master/alertNotification", {
        param: {
          title: "Error!",
          html: "Su token no está autorizado para la acción",
          timer: 15000,
          icon: "error",
          confirmButtonColor: "#d60400",
        },
      });
      router.push("/login");
    } else if (status === 500 || status === 422) {
      let response = error.response;
      //console.log(response);
      store.dispatch("access/errorRequest", {
        data: {
          datos: response.data.errors,
          status: response.status,
          response: response,
        },
      });
    }

    store.commit("master/setLoadingBtn", false);
    return Promise.reject(error);
  }
);
store.commit("master/setMenu");
// store.commit("master/setUser", {
//   user_login_id: "ronald",
//   permission: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 16, 15],
//   modules: [],
// });
// store.commit("access/setLogedIn", true);
// store.commit("master/setMenu", menuitems.state.menu);
// //localStorage.clear;
// localStorage.setItem("menu", JSON.stringify(menuitems.state.menu));
// localStorage.setItem(
//   "user",
//   JSON.stringify({
//     user_login_id: "ronald",
//     permission: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 16, 15],
//     modules: [],
//   })
// );

store.dispatch("access/checkSessionStatus");
var previousRoute = null;
//console.log(store.state.access.loggedIn);
router.beforeEach((to, from, next) => {
  //SI LA RUTA ES PROTEGIDA
  //console.log(store.state.access);
  if (to.meta.requiresAuth) {
    //if (to.matched.some((record) => record.meta.requiresAuth)) {
    //SI NO ESTA LOGUEADO
    //console.log(store.state.access.loggedIn);
    if (!store.state.access.loggedIn) {
      if (to.fullPath != "/login" && to.fullPath != "/") {
        previousRoute = to.fullPath;
      }
      //console.log("xxx" + to.fullPath);
      next({ path: "login" });
    } else {
      //AL LOGUEARSE ACCEDIENDO DESDE UNA RUTA PREVIA REDIRIJE A ESA RUTA
      if (previousRoute != null) {
        location.href = previousRoute;
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
