export default {
  namespaced: true,
  state: {
    menu: [
      {
        name: "Inicio",
        url: "/",
        icon: "fas fa-sm fa-home",
        permissions: ["HOME"],
      },

      {
        name: "Mis datos",
        url: "/datos_empleado",
        icon: "fas fa-sm fa-user",
        permissions: [18],
      },

      // {
      //   name: "Descargar documentos",
      //   url: "/descargar_docs",
      //   icon: "fas fa-sm fa-file-download",
      //   permissions: [5],
      // },
      {
        name: "Noticias",
        url: "/noticias",
        icon: "fas fa-sm fa-newspaper",
        permissions: [1],
      },
      {
        name: "Calendario",
        url: "/calendario",
        icon: "fas fa-sm fa-calendar",
        permissions: [23],
      },

      {
        name: "Solicitudes cambio de datos",
        url: "/sol_cambio_datos_emp",
        icon: "fas fa-sm fa-user-pen ",
        permissions: [26],
      },

      {
        name: "Solicitudes de vacaciones",
        url: "/sol_vacaciones_emp",
        icon: "mdi mdi-list-status",
        permissions: [25],
      },

      {
        config: true,
        name: "Administracion",
        icon: "mdi mdi-account-cog",
        permissions: [3, 4, 8, 2, 19, 24],
        dropDown: [
          {
            name: "Empresas",
            url: "/empresas",
            icon: "fas fa-building",
            permissions: [3],
          },
          {
            name: "Empleados",
            url: "/empleados",
            icon: "mdi mdi-32px mdi-account",
            permissions: [4],
          },
          {
            name: "Plantillas",
            url: "/plantillas",
            icon: "mdi mdi-view-dashboard-variant-outline",
            permissions: [8],
          },
          {
            name: "Solicitudes cambio de datos",
            url: "/sol_cambio_datos",
            icon: "mdi mdi-list-status",
            permissions: [2],
          },
          {
            name: "Solicitudes de vacaciones",
            url: "/sol_vacaciones",
            icon: "mdi mdi-list-status",
            permissions: [19],
          },
          {
            name: "Noticias",
            url: "/adm_noticias",
            icon: "fas fa-sm fa-newspaper",
            permissions: [24],
          },
        ],
      },
      {
        config: true,
        name: "Seguridad",
        icon: "mdi mdi-shield",
        permissions: [17, 7, 6],
        dropDown: [
          {
            name: "Permisos",
            url: "/permisos",
            icon: "mdi mdi-account-key",
            permissions: [7],
          },
          {
            name: "Privilegios",
            url: "/privilegios",
            icon: "mdi mdi-account-key",
            permissions: [17],
          },
          {
            name: "Usuarios",
            url: "/usuarios",
            icon: "mdi mdi-account",
            permissions: [6],
          },
        ],
      },
    ],
  },
};
