<style>
.v-list-group__items {
  /*--indent-padding: calc(var(--parent-padding) + var(--list-indent-size));*/
  --indent-padding: 2px;
}
</style>
<template>
  <v-app id="main">
    <v-navigation-drawer
      id="core-navigation-drawer"
      v-model="drawer"
      app
      theme="dark"
      color="#002900"
      background-color="#91881"
      mobile-breakpoint="640"
      width="250"
      v-if="getIsLoggedIn"
    >
      <template v-slot:img="props">
        <v-img :gradient="`to bottom`" v-bind="props" />
      </template>
      <v-divider class="mb-1" />

      <v-list dense nav>
        <v-list-item class="px-0 py-0">
          <template v-slot:prepend>
            <v-list-item-avatar
              class="align-self-center"
              color="white"
              small
              contain
            >
              <v-icon color="#ffffff">mdi mdi-account-convert</v-icon>
            </v-list-item-avatar>
          </template>
          <v-list-item-content>
            <v-list-item-title
              class="pl-1 py-1 text-uppercase"
              style="white-space: wrap; text-align: center"
              >{{
                user.name == null ? user.empleado : user.empleado
              }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="mb-2"></v-divider>

      <v-list expand nav dense :key="`list-fc`">
        <v-subheader>Menú</v-subheader>
        <v-list-item
          class="py-0 my-0"
          v-for="(m, x) in tienePermisos"
          :key="`menu-${x}`"
          link
          :to="m.url"
        >
          <v-list-group
            no-action
            link
            v-if="m.config == true"
            class="menu-drop-down"
          >
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props">
                <v-icon class="mr-2" :icon="m.icon"></v-icon>
                {{ m.name }}
              </v-list-item>
            </template>
            <v-list-item
              dense
              sub-group
              class="ml-0 pl-0 my-0"
              v-for="(dd, y) in getMenuSubItem(m.dropDown)"
              :key="y"
              :to="dd.url"
            >
              <template v-slot:prepend>
                <v-list-item-icon v-if="m.config" class="mr-2">
                  <v-icon :icon="dd.icon"></v-icon>
                </v-list-item-icon>
              </template>
              <v-list-item-content v-if="m.config">
                <v-list-item-title no-action>{{ dd.name }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <template v-slot:prepend>
            <v-list-item-icon v-if="!m.config" class="mr-2">
              <v-icon :icon="m.icon"></v-icon>
            </v-list-item-icon>
          </template>

          <v-list-item-content v-if="!m.config">
            <v-list-item-title no-action>{{ m.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-toolbar v-if="getIsLoggedIn" dense>
        <v-app-bar-nav-icon @click="setDrawer(!drawer)"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ this.titleToolbar }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-icon start>mdi mdi-domain</v-icon> {{ empresaUsuario }}

        <v-menu
          v-model="dropdownMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ props }">
            <v-btn color="blue-grey darken-1" dark v-bind="props" icon>
              <v-icon>mdi mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <v-card width="360px">
            <v-list lines="one">
              <v-list-item>
                <template v-slot:prepend>
                  <v-icon> mdi mdi-36px mdi-account-circle</v-icon>
                </template>
                <v-list-item-content>
                  <v-list-item-title>{{
                    user.name == null ? user.user_login_id : user.name
                  }}</v-list-item-title>
                  <!-- <v-list-item-subtitle
                    >Permiso prinicipal</v-list-item-subtitle
                  > -->
                </v-list-item-content>
                <template v-slot:append>
                  <v-btn icon @click="logout">
                    <v-icon>mdi mdi-import</v-icon>
                  </v-btn>
                </template>
              </v-list-item>
            </v-list>
          </v-card>
          <!-- <v-divider></v-divider>
            <v-col cols="12">
              <v-select
                v-model="tenantIdSelected"
                :items="companies"
                item-text="name"
                item-value="tenantId"
                label="Seleccione una empresa"
                outlined
                dense
                @change="setSelectedCompany"
              ></v-select>
            </v-col>
            <v-row class="text-center" v-if="modules.length">
              <v-col cols="6" md="4" v-if="pos" @click="setDrawer(true)">
                <v-btn height="60px" to="/posinicio">
                  <v-img src="./assets/images/pos.png" width="50px"> </v-img>
                </v-btn>
                <div class="title-icon-application mt-1">P.O.S</div>
              </v-col>

              <v-col
                cols="6"
                md="4"
                v-if="contabilidad"
                @click="setDrawer(true)"
              >
                <v-btn height="60px" to="/contabilidad">
                  <v-img src="./assets/images/nomina.png" width="50px"> </v-img>
                </v-btn>
                <div class="title-icon-application mt-1">CONTABILIDAD</div>
              </v-col>
              <v-col cols="6" md="4" v-if="store" @click="setDrawer(true)">
                <v-btn height="60px" to="/bodega">
                  <v-img src="./assets/images/bodega.png" width="50px"></v-img>
                </v-btn>
                <div class="title-icon-application mt-1">BODEGA</div>
              </v-col>
              <v-col cols="6" md="4" v-if="purchases" @click="setDrawer(true)">
                <v-btn height="60px" to="/compras">
                  <v-img src="./assets/images/compras.png" width="50px">
                  </v-img>
                </v-btn>
                <div class="title-icon-application mt-1">COMPRAS</div>
              </v-col>
              <v-col cols="6" md="4" v-if="admin" @click="setDrawer(true)">
                <v-btn height="60px" to="/administracion">
                  <v-img src="./assets/images/administrador.png" width="50px">
                  </v-img>
                </v-btn>
                <div class="title-icon-application mt-1">ADMINISTRACIÓN</div>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col class="mx-2">
                <v-alert type="error" dense>
                  Sin aplicaciones asignadas
                </v-alert>
              </v-col>
            </v-row>
            
          </v-card>
          -->
        </v-menu>
      </v-toolbar>

      <router-view class="mt-2"></router-view>
    </v-main>

    <v-footer height="54" app inset fixed class="fixed bg-grey-lighten-2">
      <v-row justify="center" no-gutters>
        <v-col class="text-center" cols="12">
          {{ new Date().getFullYear() }} — <strong>{{ company }}</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "App",
  components: {},
  data: () => ({
    dropdownMenu: false,
  }),

  computed: {
    theme() {
      return this.$vuetify.theme.name == "light" ? "light" : "dark";
    },
    tienePermisos() {
      let items = [];
      for (let i = 0; i < this.menu.length; i++) {
        if (
          this.idActionsRol.find((e) => this.menu[i].permissions.includes(e))
        ) {
          items.push(this.menu[i]);
        }
      }

      return items;
    },
    company() {
      // var c = this.companies.find((e) => e.tenantId == this.tenantId);
      return "Portal Siglobpo";
    },

    empresaUsuario() {
      if (this.user.tipo_usuario == 1) {
        return this.user.empresa;
      }
      if (this.user.tipo_usuario == 4) {
        return "Portal Siglobpo";
      }

      return "na";
    },

    ...mapGetters("access", [
      "getIsLoggedIn",
      "store",
      "purchases",
      "users",
      "admin",
    ]),

    ...mapState("access", ["modules", "idActionsRol"]),

    ...mapState("master", [
      "companies",
      "user",
      "token",
      "overlay",
      "tenantId",
      "drawer",
      "menu",
      "titleToolbar",
    ]),

    drawer: {
      get: function () {
        return this.$store.state.master.drawer;
      },
      set: function () {
        return this.$store.state.master.drawer;
      },
    },
  },
  methods: {
    ...mapActions("master", ["requestApi", "alertNotification", "setDrawer"]),
    ...mapActions("access", ["errorRequest", "getModules"]),
    ...mapMutations("master", ["setUrl", "setMenu", "setDrawer"]),
    ...mapMutations("access", [
      "setLogedIn",
      "setActionsRol",
      "setAvailableModules",
    ]),

    /*setSelectedCompany() {
      localStorage.setItem("tenantId", this.tenantIdSelected);
      this.setTenantId(this.tenantIdSelected);
      this.getModules();
    },*/

    getMenuSubItem(subitems) {
      let items = [];
      for (let i = 0; i < subitems.length; i++) {
        if (
          this.idActionsRol.find((e) => subitems[i].permissions.includes(e))
        ) {
          items.push(subitems[i]);
        }
      }
      return items;
    },

    logout() {
      this.setUrl("api/logout");
      this.requestApi({
        method: "post",
        data: {
          token: this.token,
          user_name: this.user.user_login_id,
          //dataBase: this.tenantId,
          system: "portal_siglobpo",
          comment: "ha cerrado sesión ",
        },
      })
        .then((res) => {
          localStorage.removeItem("token");
          localStorage.setItem("user", "");
          this.setMenu([]);
          this.setLogedIn(false);
          this.setActionsRol();
          //console.log(res);
          this.alertNotification({
            param: {
              html: "Adios " + this.user.name,
              toast: true,
              timer: 1000,
            },
          });
          if (!this.loggedIn) this.$router.push("/login");
        })
        .catch((err) => {
          //console.log(err);
          let response = err.response;
          this.errorRequest({
            data: {
              datos: response.data.errors,
              status: response.status,
            },
          });
        });
    },
  },
  beforeMount() {},

  mounted() {
    //this.setMenu(JSON.parse(localStorage.getItem("menu")));
    document.title = "Portal de Talento Humano";
  },
};
</script>
<style>
/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
*/
/*nav {
  background-color: aqua;
  color: antiquewhite;
}*/

.v-theme--dark.v-navigation-drawer {
  background-color: #363636;
}
/*nav a {
  font-weight: bold;
  color: #2c3e50;
}*/

/*nav a.router-link-exact-active {
  color: #42b983;
}*/

.v-list-group {
  width: 100%;
}

.v-list-group__header {
  padding: 0 !important;
}

.menu-drop-down {
  font-size: 12px;
  font-weight: 500;
}

.v-list-group__header__append-icon {
  position: absolute;
  right: 0;
}
</style>
